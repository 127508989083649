import * as React from "react"
import Layout from "../components/layout"
import Pdf from "../images/pdf/wakachie-user-gide.pdf"

const UserGidePage = () => {
  return (
    <Layout>
      <div className="downloadContainer">
        <h2 className="downloadTitle">ユーザーガイドダウンロードページ</h2>
        <p>
          ユーザーガイドの確認ありがとうございます。
          <br /> こちらのページより資料のダウンロードが可能です。
        </p>

        <a href={Pdf} download>
          <p>ユーザーガイド ダウンロード</p>
        </a>
      </div>
    </Layout>
  )
}

export default UserGidePage
